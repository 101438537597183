<template>
  <section id="platform-settings-learning">
    <!-- Bucket configuration -->
    <b-overlay
      :show="!isElearningPlatform"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mb-2">
        <b-col>
          <h1>{{ $t("learning") }}</h1>
          <h5 class="text-primary">
            {{ $t("modeloPlatf") }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="match-height mt-2">
        <b-col>
          <b-card>
            <b-form @submit.prevent="updateLearning">
              <b-row class="m-1">
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    label="Modo"
                  >
                    <div class="d-flex">
                      <b-form-select
                        v-model="elearningPlatformType"
                        :options="typeLearning"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="m-1">
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('urlLearning')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="elearningPlatformUrl"
                        autocomplete="new-password"
                        class="mr-1"
                        :placeholder="$t('urlLearning')"
                        maxlength="250"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="m-1">
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('tokenLearning')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="elearningPlatformToken"
                        autocomplete="new-password"
                        class="mr-1"
                        :placeholder="$t('tokenLearning')"
                        maxlength="250"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="m-1">
                <b-col style="text-align: end;">
                  <b-button
                    type="submit"
                    variant="success"
                  >
                    {{
                      $t("dataGeneric.save")
                    }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
      <template #overlay>
        <div class="text-center">
          <b-jumbotron
            bg-variant="transparent"
            border-variant="primary"
            :header="$t('desactValue')"
            class="text-center"
          >
            <b-row>
              <b-col>
                <h5>
                  {{ $t('activeShure') }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  no-body
                  @click="changeFunction(funcionalidad)"
                >
                  <b-avatar
                    class="cursor-pointer mb-2 mt-2"
                    size="70"
                    :variant="funcionalidad.value ? 'light-success' : 'light-danger'
                    "
                  >
                    <feather-icon
                      size="30"
                      :icon="funcionalidad.icon"
                    />
                  </b-avatar>
                  <h6 class="">
                    {{ funcionalidad.titulo }}
                    <feather-icon
                      v-b-tooltip.hover.top
                      :title="funcionalidad.descripcion"
                      icon="InfoIcon"
                    />
                  </h6>
                  <!-- <small>{{ funcionalidad.descripcion }}</small> -->
                </div>
              </b-col>
            </b-row>
          </b-jumbotron>
        </div>
      </template>
    </b-overlay>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BJumbotron,
  BOverlay,
  VBTooltip,
  BAvatar,
  BFormSelect,
  BFormInput,
  BForm,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, setClientNode } from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormSelect,
    BButton,
    BFormGroup,
    BForm,
    BJumbotron,
    BOverlay,
    BAvatar,
    BFormInput,
  },

  directives: {
    'b-tooltip': VBTooltip,

    Ripple,
  },
  data() {
    return {
      elearningPlatformType: 'MOODLE',
      elearningPlatformUrl: null,
      typeLearning: { MOODLE: 'Moodle', BLACKBOARD: 'Blackboard' },
      elearningPlatformToken: null,
      funcionalidad: {},
      isElearningPlatform: false,
    }
  },

  created() {
    const userData = getUserData()
    if (!userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    this.isElearningPlatform = getUserData().profile.client.isElearningPlatform

    axios
      .post('', {
        query: `{
          client(id: "${userData.profile.client.id}") {
            elearningPlatformToken
            elearningPlatformUrl
            elearningPlatformType
            isElearningPlatform
          }
        }`,
      })
      .then(r => {
        this.funcionalidad = {
          value: r.isElearningPlatform,
          titulo: this.$t('learning'),
          descripcion: this.$t('learningInfo'),
          icon: 'BookOpenIcon',
          id: 'isElearningPlatform',
        }
        this.elearningPlatformToken = r.data.data.client.elearningPlatformToken
        this.elearningPlatformType = r.data.data.client.elearningPlatformType === null ? 'MOODLE' : r.data.data.client.elearningPlatformType
        this.elearningPlatformUrl = r.data.data.client.elearningPlatformUrl
      })
  },
  methods: {
    changeFunction() {
      const query = `
        mutation{
          updateClient(id:"${getUserData().profile.client.id}",input:{isElearningPlatform:true}){
            client{
              id
              name
              isShop
              hasProfiles
              isBuy
              isInteractivity
              isFavorite
              videotype
              isSecondScreenInteractivity
              isPushInteractivity
              isBlockInteractivity
              isElearningPlatform
              hasMyChannel
              isVirtualUser
              isClose
              isPromotionalCodes
              isDownload
              isParentalControl
              isAds
              hasRating
              hasAdSlides
              hasAdCuePoints
              contentRating
              productRating
              categoryRating
              isLinkCategories
              isLive
              isTranscoding
              geoipbloking
            }
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        showToast(this.$t('success'), 1, this)
        setClientNode()
      })
    },
    updateLearning() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const userData = getUserData()

      const query = `
              mutation($id: ID!,
              $elearningPlatformType: ClientElearningPlatformTypeInput,
              $elearningPlatformToken: String,
              $elearningPlatformUrl: String){
              updateClient(id: $id,input:{
                elearningPlatformType:$elearningPlatformType,
                elearningPlatformToken:$elearningPlatformToken,
                elearningPlatformUrl:$elearningPlatformUrl
              }) {
                client{
                  name
                  id
                }
              }
            }
          `
      const variables = {
        id: userData.profile.client.id,
        elearningPlatformType: this.elearningPlatformType,
        elearningPlatformToken: this.elearningPlatformToken,
        elearningPlatformUrl: this.elearningPlatformUrl,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },

  },
}
</script>
